import { Country, Currency, SocialMedia, SportGender } from "@/../types/enums";
import { CoachDetailModel, ESSchoolModel, LocationModel, SchoolModel, SportDetailModel } from "./SchoolModel";

export const DummyCoach: CoachDetailModel = new CoachDetailModel().load({
	name: "Dummy Coach",
	title: "Head of Sport",
	email: "coach@email.com",
	phone: "1112223333",
	fax: "1112223333",
	ccc_person_id: 0,
})
export const DummySport: SportDetailModel = new SportDetailModel().load({
	name: 'Soccer',
	gender: SportGender.MEN,
	division: 'NCAA I',
	conference: 'PAC-12',
	facility: 'Stadium',
	coaches:  [DummyCoach, DummyCoach],
	socialMedia:  [
		{ platform: SocialMedia.Twitter, link:'https://twitter.com/bestathletesco' }, 
		{ platform: SocialMedia.Facebook, link:'https://www.facebook.com/bestathletesco' }, 
	],
	website: 'https://bestathletes.co/',
})
export const DummyLocation = new LocationModel().load({
	cccSchoolId: 0,
	address: "",
	city: "",
	state: "",
	zipcode: "",
	phone: "",
	fax: "",
	country: Country.US,
	socialMedia: [],
	sports: [DummySport,]
})

export const DummySchool: SchoolModel = new SchoolModel().load({
	nicheUuid: "",
	ipedsId: 0,
	locations: [ DummyLocation ],
	schoolType: "",
	governance: "",

	testConsideration: "",
	freshmanEnrolled: 0,

	athleteAssociation: "",
	acceptanceRate: 0,
	undergradsEnrolled: 0,
	maleToFemaleUndergradRatio: 0,
	studentFacultyRatio: 0,
	graduationRate: 0,
	retentionRate: 0,
	satRequirement: 0,
	satCompositeScore: { min: 400, max: 1600 },
	satMath: { min: 400, max: 1600 },
	satReading: { min: 400, max: 1600 },
	actRequirement: 0,
	actCompositeScore: { min: 1, max: 36 },
	actMath: { min: 1, max: 36 },
	actEnglish: { min: 1, max: 36 },
	gpa: 0,
	courseRequirements: [],
	inStateTuition: 0,
	outStateTuition: 0,
	netPrice: 0,
	fees: 0,
	currency: Currency.USD,
	onCampusHousingAvailable: false,
	housingCost: 0,
	financialAidProcess: "",
	financialAidPackagesAvailable: [],
	percentFreshmanAidGranted: 0,
	federalAidGranted: 0,
	popularMajors: [],
	socialMedia: [],

	schoolLogo: "",
});


const DummySchoolsList: SchoolModel[] = [];
for (let i = 0; i < 20; i++) {
	DummySchoolsList.push(DummySchool)
}

export const ESDummySchool = new ESSchoolModel().load({
	...DummySchool,
	locations: DummyLocation,
})
const ESDummySchoolList: ESSchoolModel[] = [];
for (let i = 0; i < 20; i++) {
	ESDummySchoolList.push(ESDummySchool)
}


export { DummySchoolsList, ESDummySchoolList };