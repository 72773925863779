
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AthleteMetric, AthleteMetricValues } from '@/../types/enums';
import * as Routes from '@/../types/constants/web_client_user.routes';
import Page from './Page.vue';
import AthleteStatCard from '../components/profile/athlete/AthleteStatCard.vue';
import ComingSoonCard from '../components/ui/ComingSoonCard.vue'
import EditOverlay from '../components/ui/EditOverlay.vue'
import JerseySvg from '../components/svg/JerseySvg.vue'
import ImageUploadDialog from '../components/profile/ImageUploadDialog.vue'
import { VuetifyMixin, AuthMixin, AthleteApiMixin } from '../mixins';
import {athleteProfile} from '../store';
import RadarChartSvg from '../components/svg/RadarChartSvg.vue'
import MetricSvg from '../components/svg/MetricSvg.vue'
import TacticalClipboardSvg from '../components/svg/TacticalClipboardSvg.vue'
import VideoClipSvg from '../components/svg/VideoClipSvg.vue'
import GraduateCapSvg from '../components/svg/GraduateCapSvg.vue'
import AssessmentDataInfoProvider from '../components/hoc/AssessmentDataInfoProvider.vue';
import { formatDateMonthDay } from '../helpers';
import { formatNumber } from '../pipes/formatNumber.pipe';
import { AthleteAssessmentDataModel } from '../models/athlete/AthleteAssessmentDataModel';
import { AthleteProfileModel } from '../models/athlete/AthleteProfileModel';
import { AthleteComparativeDataSummary } from "@/../types/constants/web_client_user.routes";
import AthleteVerifiedAssessmentsCard from "@/components/profile/athlete/AthleteVerifiedAssessmentsCard.vue";

@Component({
	components:{
		AthleteVerifiedAssessmentsCard,
		AthleteStatCard,
		AssessmentDataInfoProvider,
		ComingSoonCard,
		EditOverlay,
		ImageUploadDialog,
		Page,
		JerseySvg,
		RadarChartSvg,
		MetricSvg,
		TacticalClipboardSvg,
		VideoClipSvg,
		GraduateCapSvg,
	},
	async beforeRouteEnter(to, _, next){
		next((vm: AthleteVerifiedAssessmentSummary) => {
			if(vm.athleteId !== null){
				athleteProfile.getAthleteProfile(vm.athleteId);
				athleteProfile.loadVerifiedAssessments({athleteId: vm.athleteId});
			}else{
				athleteProfile.getAthleteProfile(vm.$auth.athleteId);
				athleteProfile.loadVerifiedAssessments({athleteId: vm.$auth.athleteId});
			}
		});
	},
	async beforeRouteUpdate(_, __, next){
		if(this.athleteId !== null){
			athleteProfile.getAthleteProfile(this.athleteId);
			athleteProfile.loadVerifiedAssessments({athleteId: this.athleteId});
		}else{
			athleteProfile.getAthleteProfile(this.$auth.athleteId);
			athleteProfile.loadVerifiedAssessments({athleteId: this.$auth.athleteId});
		}
		next();
	},
})
export default class AthleteVerifiedAssessmentSummary extends Mixins(AuthMixin, VuetifyMixin, AthleteApiMixin){
	formatDateMonthDay = formatDateMonthDay;
	formatNumber = formatNumber;
	@Prop({ default: null }) athleteId: string | null;

	onAddAssessment(){
		this.$router.push({
			name: Routes.AthleteClaimAssessmentForm,
			params:{
				...this.$route.params,
			}
		});
	}

	onViewComparativeData(metric: AthleteMetric): void{
		// view the comparative data for the specified metric for this athlete
		this.$router.push({
			name: AthleteComparativeDataSummary,
			params:{
				metric,
				athleteId: this.athleteId,
			}
		});
		// go to the top of the page
		this.$vuetify.goTo(0);
	}

	get Loading(): boolean{
		return athleteProfile.IsLoading || athleteProfile.verifiedAssessmentsLoading || !this.loadedSports;
	}
	get AthleteMetrics(): AthleteMetric[]{
		return AthleteMetricValues;
	}
	get HasVerifiedAssessments(): boolean {
		if( !this.VerifiedAssessments ) return false;
		return ( this.VerifiedAssessments.length > 0 );
	}
	get FirstAssessment(): AthleteAssessmentDataModel{
		return this.VerifiedAssessments[0];
	}
	get VerifiedAssessments(): AthleteAssessmentDataModel[]{
		return athleteProfile.verifiedAssessments.slice().sort((a,b) => {
			return +b.assessmentDate - +a.assessmentDate;
		});
	}
	get AthleteProfile(): AthleteProfileModel{
		return athleteProfile.athleteProfile;
	}
	get Acceleration(): AthleteMetric.Acceleration{ return AthleteMetric.Acceleration; }
	get Speed(): AthleteMetric.Speed{ return AthleteMetric.Speed; }
	get Agility(): AthleteMetric.Agility{ return AthleteMetric.Agility; }
	get Power(): AthleteMetric.Power{ return AthleteMetric.Power; }
	get Recovery(): AthleteMetric.Recovery{ return AthleteMetric.Recovery; }

	goToMetric(metric: AthleteMetric){
		this.$router.push({
			name: Routes.AthleteComparativeDataSummary,
			params:{
				...this.$route.params,
				metric
			}
		});
	}
}
