

interface Point {
	x: number;
	y: number
}

import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { VuetifyMixin, AthleteMetricsMixin } from '../../mixins';
import { RadarChartSeries } from "@/../types/interfaces/RadarChartSeries";
import { AthleteMetric } from '@/../types/enums';

const sampleData: RadarChartSeries[] = [
	{
		values: [ 1, 1, 1, 1, 1],
		color: "baColorLightBlue",
		borderColor: "baColorDeepBlue",
		name: ""
	}];

export const METRIC_LABEL_MAP: Record<string, AthleteMetric> = {
	"Accel.": AthleteMetric.Acceleration,
	"Speed": AthleteMetric.Speed,
	"Agility": AthleteMetric.Agility,
	"Power": AthleteMetric.Power,
	"Recovery": AthleteMetric.Recovery,
};


@Component
export default class RadarChartComparative extends Mixins(AthleteMetricsMixin, VuetifyMixin){

	labelMetricMap: Record<string, AthleteMetric> = METRIC_LABEL_MAP;

	@Prop({ default: null }) pictureUrl: string | null;
	defaultPictureUrl: string = 'https://cdn.bestathletes.co/static-assets/bestie-square-100x100.png';
	@Prop({ default: 'baColorDeepBlue' }) private pinColor: string;
	get PinColor(): string{
		return this.getColor(this.pinColor);
	}

	@Prop({default: 100, type: Number }) private scaleMax: number;
	get labels(): string[]{
		return Object.keys(this.labelMetricMap);
	}
	getLabelMap(mapFn: (label: string) => {label: string, value: any}): Record<string, any>{
		return this.labels
			.map(mapFn)
			.reduce((a,b) => ({...a,[b.label]: b.value}), {});
	}
	get LabelIconComponents(): Record<string, any>{
		return this.getLabelMap(label => ({ label, value: this.getMetricIcon(this.labelMetricMap[label]) }));
	}
	get LabelIconColors(): Record<string, any>{
		return this.getLabelMap(label => ({ label, value: this.getMetricColor(this.labelMetricMap[label]) }));
	}
	@Prop({default: sampleData}) public data: RadarChartSeries[];
	get ChartData(): RadarChartSeries[]{
		return this.data.map(series => ({
			...series,
			color: series.color || this.color,
			borderColor: series.borderColor || this.borderColor,
		}));
	}
	@Prop({default: true}) private showPin: boolean;
	get ShowPin(): boolean{
		return this.showPin;
	}
	@Prop({default: 48}) private pinHeight: number;
	// Default series colors
	@Prop({default: "baColorLightBlue2"}) private color: string;
	@Prop({default: "baColorPrimaryBlue"}) private borderColor: string;

	public SVG_NAMESPACE: string = "http://www.w3.org/2000/svg";
	public chartVertices: Point[] = [];
	chartSideLength: number = 0;
	chart: SVGElement | null = null;
	chartCenter: Point;
	interiorAngle: number;

	get NumMetrics(): number {
		// assumption that all series have the same # of metrics for a single chart
		return this.ChartData[0].values.length
	}

	get LabelsByLevel() : Array<{isSingle: boolean, label: string}> {
		let labels = this.labels.slice();
		const labelsByLevel = [ { isSingle: true, label: labels.shift()  }  ]; // first point
		while (labels.length >= 2){ // side-labels, traverse outside in
			labelsByLevel.push({ isSingle: false, label: labels[0] });
			labelsByLevel.push({ isSingle: false, label: labels[labels.length -1] });
			labels = labels.slice(1, -1);
		}
		if (labels.length == 1){ // final point (even-sided charts)
			labelsByLevel.push({ isSingle: true, label: labels[0] });
		}
		return labelsByLevel;
	}

	beforeMount(): void {
		this.initializeCanvas();
	}

	@Watch('ChartData') chartDataChanged(): void{
		this.$nextTick(() => {
			this.chart = this.$refs.chart as SVGElement;
			this.setViewboxDimensions();
		});
	}
	mounted(): void {
		this.chartDataChanged();
	}

	private initializeCanvas() {
		/*
		* interiorAngle = sumOfInteriorAngles / numMetrics
		* where numMetrics = # of sides
		* Ex square:  (4-2)*180 / 4 = 90deg corners
		* Ex pentagon: (5-2)*180 / 5 = 108deg corners
		*/
		this.interiorAngle = ((this.NumMetrics-2)*180) / this.NumMetrics;
		/* Law of Cosines :: c^2 = a^2 + b^2 - 2ab*cos(C)
		* We apply this so that the distance from the chart-center to any axis vertex is 100px.
		* Thus, a score or 3/4 will be 75px from the center of the axis
		* a = b = 100; where a and b are distances from the chart origin to axis vertices.
		* therefore, c^2 = 2(100^2) - 2(100^2)*cos(C)
		*/
		this.chartSideLength = Math.sqrt(
			(2*Math.pow(100, 2)) -
			(2*Math.pow(100, 2) * Math.cos(this.toRadians(180 - this.interiorAngle)))
		);

		const chartVertices: Point[] = [ {x: 0, y: 0 } ];
		let angle:number = 360 - ((180 - this.interiorAngle) / 2);
		let currentPoint = chartVertices[0];
		while (chartVertices.length < this.NumMetrics){
			currentPoint = this.getVectorComponents(angle, currentPoint, this.chartSideLength);
			chartVertices.push({x: Math.round(currentPoint.x), y: Math.round(currentPoint.y)})
			angle = angle - (180 - this.interiorAngle);
		}
		this.chartVertices =  chartVertices;
		this.chartCenter = this.calculateCenter(chartVertices);
	}

	public getSeriesPoints(values: number[]) : Point[] {
		const points: Point[] = [];
		let angle: number = 90;
		for (let value of values){
			const metricValueAsPercent = 100 * value / this.scaleMax;
			const y = this.chartCenter.y - Math.sin(this.toRadians(angle)) * metricValueAsPercent;
			const x = this.chartCenter.x - Math.cos(this.toRadians(angle)) * metricValueAsPercent;
			angle = angle + (180 - this.interiorAngle);
			points.push({x, y});
		}
		return points;
	}

	getRectLabelLocation(point: Point): Point {
		return { x: point.x, y: point.y - 10 };
	}
	getValueLabelLocation(point: Point): Point {
		return { x: point.x + 20, y: point.y + 1 };
	}

	private setViewboxDimensions() {
		const labels: SVGTextElement[] = Array.from(this.chart.querySelectorAll(".label-value"));
		let viewBox = { right: 0, left: 0, top: 0, bottom: 0 }
		for (const label of labels){
			viewBox = this.ensureLabelWithinViewBox(label, viewBox);
		}
		const viewBoxHeight = viewBox.bottom - viewBox.top;
		const viewBoxWidth = viewBox.right - viewBox.left;
		const viewBoxAttr = `${viewBox.left} ${viewBox.top} ${viewBoxWidth} ${viewBoxHeight}`;
		this.chart.setAttribute("viewBox",  viewBoxAttr);
	}

	public getGridRow(labelIndex: number): string {
		const gridRow = Math.floor((labelIndex + 1) / 2) + 1;
		return `${gridRow} / ${gridRow}`;
	}

	public normalizeSeriesName(name: string): string {
		return "series-" + name.replace(/\W/, "-").toLowerCase();
	}

	public getLogoDimensions(): { width: number, height: number }{
		const logoAspectRatio = 35/48;
		const height = this.pinHeight;
		const width = height * logoAspectRatio;
		return { width, height }
	}

	public pointsAsPath(points: Point[]): string {
		if (points.length == 0){
			return "M 0 0 Z";
		}
		return "M " + points.map(point => `${point.x} ${point.y}`).join(" ") + " Z";
	}

	private ensureLabelWithinViewBox(label: SVGTextElement,
		viewBox: { right: number, left: number, top: number, bottom: number }){
		const { width, height, x, y } = label.getBBox();
		const labelTop = y;
		const labelBottom = y + height;
		const labelRight = x + width;
		const labelLeft = x;
		const chartVerticesHorizontal = this.chartVertices.map(vertex=>vertex.x);
		const chartVerticesVertical = this.chartVertices.map(vertex=>vertex.y);
		// +/- 2px from sides to account for stroke-width
		viewBox = {
			right: [
				...chartVerticesHorizontal.map(side=>side+2), labelRight
			].reduce((a, b) => Math.max(a, b), viewBox.right),
			left: [
				...chartVerticesHorizontal.map(side=>side-2), labelLeft
			].reduce((a, b) => Math.min(a, b), viewBox.left),
			bottom: [
				...chartVerticesVertical.map(side=>side+2), labelBottom
			].reduce((a, b) => Math.max(a, b), viewBox.bottom),
			top: [
				...chartVerticesVertical.map(side=>side-2), labelTop
			].reduce((a, b) => Math.min(a, b), viewBox.top),
		};
		return viewBox;
	}

	private toRadians(degrees: number): number {
		return degrees * (Math.PI / 180);
	}

	private calculateCenter(points: Point[]): Point{
		const x = points.map(point=>point.x).reduce((a, b)=>a + b) / this.NumMetrics;
		const y = points.map(point=>point.y).reduce((a, b)=>a + b) / this.NumMetrics;
		return { x, y };
	}

	/* destination x,y after heading <distance> in <angle> direction from <start> */
	private getVectorComponents(angle: number, start: Point, distance: number): Point {
		const x = start.x + Math.cos(this.toRadians(angle)) * distance;
		// for SVGs, an increasing Y-value implies downward movement. (*-1)
		const y = start.y + Math.sin(this.toRadians(angle)) * distance * -1;
		return {x, y };
	}
}

