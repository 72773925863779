
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin, AthleteRoutingMixin, AuthMixin, BAIconsMixin, MyAthleteMixin, ScoutingReportScoreSelectorMixin, StringsMixin } from "@/mixins";
import { AthleteReportModelEx } from "@/models/athleteReport/AthleteReportModel";
import { athleteReportApi } from "@/api/AthleteReportApi"
import ScoreSelector from '@/components/forms/ScoreSelector.vue';


@Component({
	components: {
		ScoreSelector
	},
})
export default class AthleteReport extends Mixins(AuthMixin, VuetifyMixin, StringsMixin, BAIconsMixin, MyAthleteMixin, AthleteRoutingMixin, ScoutingReportScoreSelectorMixin) {
	@Prop() reportId: string;

	athleteReport: AthleteReportModelEx;

	isReportLoading: boolean = false;
	isReportLoaded: boolean = false;
	isReportChanging: boolean = false;
	isReportChanged: boolean = false;
	isReportReady: boolean = false;

	isYouth: boolean = true;

	mounted() {
		this.initializePage();
	}

	async initializePage() {
		await this.initializeReport();
		await this.loadReport();
	}
	async initializeReport() {
		this.athleteId = this.TheAthleteId;
		this.athleteReport = undefined;
	}
	async loadReport() {
		this.isReportLoaded = false;
		this.isReportLoading = true;

		const foundReport = await athleteReportApi.findById( this.reportId );
		this.athleteReport = new AthleteReportModelEx().load(foundReport);
		if( this.IsNotEmpty(this.athleteReport) ) {
			await this.athleteReport.loadAthlete();
			await this.athleteReport.loadAthleteCoach();
		}

		this.isReportLoading = false;
		this.isReportLoaded = true;
	}
}

